.events-view {
    padding: 10px;
    margin-bottom: 0px;
  }
  
  .events-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Avant Garde', sans-serif;
    margin-bottom: 20px;
    margin-top: 0px;
    text-transform: uppercase;
    color: #000000;
  }
  
  .calendar-popup {
    position: absolute;
    z-index: 1000;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 70%;
    max-width: 350px; /* Make the popup take up most of the screen width on small screens */
    left: 50%; /* Center the popup horizontally */
    transform: translateX(-50%); /* Center the popup horizontally */
      
      top: 240px; /* Adjust the top position for better visibility */
  }

  
  .event-item {
    background-color: #f0f0f0;
    border-radius: 5px;
    font-family: 'Avant Garde', sans-serif;
    color: #000000;
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .date-group {
    margin-bottom: 30px;
    font-family: 'Avant Garde', sans-serif;
    color: #000000;
  }

  .date-group h3 {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .event-item h4 {
    margin-top: 0;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .location-info-button {
    padding: 10px;
    font-size: 12px;
    background-color: #71c7fd;
    color: #000000;
    border: 0;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'Avant Garde', sans-serif;
    font-weight: bold;
  }

  .location-info-button:hover {
    background-color: #25a4f9;
  }

  